<template>
  <b-sidebar
    id="table-settings"
    body-class="custom-scroll color-black"
    v-model="showTableSettingsSideBar"
    aria-labelledby="Table settings"
    aria-label="Table settings"
    shadow
    bg-variant="white"
    :backdrop-variant="`dark`"
    no-close-on-esc
    no-close-on-backdrop
    lazy
    backdrop
    right
    width="70vw"
    @shown="assignEditTable()"
    @hidden="sideBarHidden"
  >
    <template #header="{ hide }">
      <h3 class="font-21 theme-font-medium mb-0">Table settings</h3>
      <div class="sidebar-header-close">
        <a
          href="javascript:void(0)"
          aria-labelledby="Table settings title"
          @click="
            hide;
            $emit('closeSideBar');
          "
          ><img src="../../assets/img/close.png" alt="close-tab"
        /></a>
      </div>
    </template>
    <template #default="{}">
      <b-row>
        <b-col sm="5">
          <h4 class="page-sub-header">Configuration</h4>
          <b-form
            id="table-configurations-from"
            name="table-configurations-from"
            autocomplete="off"
            novalidate
          >
            <b-form-row>
              <b-col>
                <b-form-group>
                  <div class="floating-input-field">
                    <b-form-input
                      id="table-title"
                      :class="{
                        'is-invalid':
                          (!formData.tableTitle && formSubmitted) ||
                          tableTitleExistError,
                      }"
                      type="text"
                      placeholder=" "
                      v-model="formData.tableTitle"
                      @input="tableTitleExistError = false"
                      required
                    ></b-form-input>
                    <label for="table-title">Table title</label>
                    <div class="additional-info">
                      <small>This should match the key in API response *</small>
                    </div>
                    <b-form-invalid-feedback
                      class="d-block"
                      v-if="!formData.tableTitle && formSubmitted"
                      >Table title required.</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback
                      class="d-block"
                      v-if="tableTitleExistError && formSubmitted"
                      >Table title already exist.</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group>
                  <div class="floating-input-field">
                    <b-form-input
                      id="table-subtitle"
                      type="text"
                      placeholder=" "
                      v-model="formData.subTitle"
                    ></b-form-input>
                    <label for="table-subtitle"
                      >Table subtitle (Optional)</label
                    >
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
          <b-form-row class="mt-3">
            <b-col cols="12">
              <h4 class="page-sub-header mb-3">Sample api response</h4>
              <div
                class="sample-api-response custom-scroll"
                :style="{ height: `${sampleResHeight}px` }"
              >
                <pre class="pl-2 custom-scroll">
{
    "id": 1,
    "key": "{{ formData.tableTitle }}",
    "detail": "Some detail info",
    "value": "{{ getSampleAPIValue }}",
    "showTable": true,
    "description": "Some description info"
}
</pre
                >
              </div>
            </b-col>
          </b-form-row>
        </b-col>
        <b-col sm="7">
          <h4 class="page-sub-header">Preview</h4>
          <div class="table-settings-preview p-3" id="table-settings-preview">
            <div class="table-preview" id="table-preview">
              <div class="d-flex align-items-center justify-content-between">
                <div
                  class="table-preview-title w-100"
                  v-if="formData.tableTitle"
                >
                  {{ formData.tableTitle }}
                </div>
                <div
                  v-b-popover.html.hover="`Some description info`"
                  v-if="formData.tableTitle"
                  class="ml-1"
                >
                  <BIconInfoCircle class="color-gray" scale="1.2" />
                </div>
              </div>
              <div class="font-14 text-center" v-if="formData.subTitle">
                {{ formData.subTitle }}
              </div>
              <PreviewTable class="mt-2" />
            </div>
            <CustomizeTableData class="mt-4" />
          </div>
        </b-col>
      </b-row>
    </template>
    <template #footer="{}">
      <b-button
        type="button"
        v-activeBlur
        class="btn-h-44"
        variant="outline-secondary"
        @click="onResetTableForm"
        >Reset</b-button
      >
      <b-button
        type="button"
        v-activeBlur
        @click="onAddUpdateTableForm"
        variant="primary"
        class="ml-3 btn-h-44"
        >{{ editTableData ? `Update` : `Add` }}
      </b-button>
    </template>
  </b-sidebar>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import { BIconInfoCircle } from 'bootstrap-vue'
import { useValidateFields } from '@/composables/useValidateFields'
export default {
  name: 'TableSettings',
  setup() {
    const { addEscapeCharacter, scrollToErrorMessage } = useValidateFields()

    return { addEscapeCharacter, scrollToErrorMessage }
  },
  props: [
    'showTableSettingsSideBar',
    'widgetTblConfigurations',
    'editTableData'
  ],
  components: {
    BIconInfoCircle,
    PreviewTable: () => import('./PreviewTable.vue'),
    CustomizeTableData: () => import('./CustomizeTableData.vue')
  },
  data () {
    return {
      modalShow: true,
      formSubmitted: false,
      formData: {
        tableTitle: null,
        subTitle: null
      },
      tableTitleExistError: false,
      filterEditList: [],
      sampleResHeight: 400
    }
  },
  computed: {
    getSampleAPIValue () {
      let result = '[('
      const fr = this.tableSampleData.head.filter((h) => h)
      fr.forEach((d, index) => {
        result += this.addEscapeCharacter(d)
        result += index + 1 === fr.length ? '' : ','
      })
      result += ')'
      const list = this.tableSampleData.body.filter(
        (b) => b.filter((v) => v).length
      )
      list.forEach((element) => {
        const fr = element.filter((h) => h)
        result += ',('
        fr.forEach((d, index) => {
          result += this.addEscapeCharacter(d)
          result += index + 1 === fr.length ? '' : ','
        })
        result += ')'
      })
      result += ']'
      return result
    },
    ...mapState({
      tableSampleData: (state) => state.widgets.tableSampleData
    })
  },
  mounted () {
    this.assignEditTable()
  },
  methods: {
    assignEditTable () {
      if (this.editTableData) {
        this.formData = cloneDeep(this.editTableData)
        this.filterEditList = this.widgetTblConfigurations.filter(
          (t) =>
            t.tableTitle.toLowerCase() !==
            this.editTableData.tableTitle.toLowerCase()
        )
      }
    },
    getSampleResHeight () {
      setTimeout(() => {
        const preview = document.getElementById('table-settings-preview')
        const tablePreview = document.getElementById(
          'table-configurations-from'
        )
        const height =
          preview && tablePreview
            ? preview.offsetHeight - tablePreview.offsetHeight - 55
            : 0
        this.sampleResHeight = height > 0 ? height : 400
      }, 600)
    },
    onAddUpdateTableForm () {
      this.formSubmitted = true
      if (this.formData.tableTitle) {
        if (this.editTableData) {
          this.tableTitleExistError = this.filterEditList.some(
            (table) =>
              table.tableTitle.toLowerCase() ===
              (this.formData.tableTitle
                ? this.formData.tableTitle.toLowerCase()
                : null)
          )
        } else {
          this.tableTitleExistError = this.widgetTblConfigurations.some(
            (table) =>
              table.tableTitle.toLowerCase() ===
              (this.formData.tableTitle
                ? this.formData.tableTitle.toLowerCase()
                : null)
          )
        }
      } else {
        this.tableTitleExistError = false
      }
      this.scrollToErrorMessage()
      this.getSampleResHeight()
      if (!this.tableTitleExistError && this.formData.tableTitle) {
        this.$emit('addUpdateTable', this.formData)
      }
    },
    onResetTableForm () {
      this.formSubmitted = false
      this.tableTitleExistError = false
      this.formData = this.editTableData
        ? cloneDeep(this.editTableData)
        : {
          tableTitle: null,
          subTitle: null
        }
    },
    sideBarHidden () {
      this.formSubmitted = false
      this.tableTitleExistError = false
      this.formData = {
        tableTitle: null,
        subTitle: null
      }
    }
  },
  watch: {
    formData: {
      deep: true,
      handler () {
        this.getSampleResHeight()
      }
    },
    tableSampleData () {
      this.getSampleResHeight()
    }
  }
}
</script>
<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
}
</style>
